import { useRouter } from 'next/router'
import PopupModal, { PopupModalProps } from './PopupModal'

const GoFavoritesModal = (props: PopupModalProps) => {
  const { onClose } = props
  const router = useRouter()
  const handleGoFavorites = () => {
    router.push('/settings/favorites')
  }

  return (
    <PopupModal {...props}>
      <PopupModal.Title fontSize="3" mobileFontSize="2">
        Let there be sports.
      </PopupModal.Title>
      <PopupModal.Subtitle fontSize="1">
        Welcome to the Team. To get the most out of our products, set your favorite teams for a customized experience.
      </PopupModal.Subtitle>
      <PopupModal.Button buttonStyle="primary" buttonSize="sm" onClick={handleGoFavorites}>
        SET FAVORITES
      </PopupModal.Button>
      <PopupModal.Button buttonStyle="alt" buttonSize="sm" onClick={onClose}>
        SKIP
      </PopupModal.Button>
    </PopupModal>
  )
}

export default GoFavoritesModal
