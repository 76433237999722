import { GrandstandIcon } from '@grandstand-web/bally-web-shared/src/components/GrandstandIcon'
import { breakpoints } from '@grandstand-web/bally-web-shared/src/styles/breakpoints'
import { ButtonHTMLAttributes, PropsWithChildren, useEffect } from 'react'
import styled from 'styled-components'
import { HeadingLabel, SubheadingFontSize, SubheadingLabel } from '../../styles/fonts'
import { ButtonSize, ButtonStyle, GrandstandButton } from '../Buttons'

const Wrapper = styled.div<{ show: boolean; showCloseButton?: boolean }>`
  --title-padding-top: ${({ showCloseButton }) => (showCloseButton ? '40px' : '0')};
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10000;
  display: ${({ show }) => (show ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  padding: 16px;
  ${breakpoints.up('tablet')} {
    --title-padding-top: ${({ showCloseButton }) => (showCloseButton ? '32px' : '0')};
    padding: 24px;
  }
`
const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
`

const Modal = styled.div<{ textAlign?: 'center' | 'left' | 'right' }>`
  position: relative;
  z-index: 2;
  width: 100%;
  max-width: 528px;
  color: var(--on-background);
  background-color: var(--surface);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 16px;
  text-align: ${({ textAlign }) => textAlign ?? 'center'};
  gap: 24px;
  ${breakpoints.up('md')} {
    padding: 32px 24px;
  }
`

type TextProps = {
  fontSize?: SubheadingFontSize
  tabletFontSize?: SubheadingFontSize
  mobileFontSize?: SubheadingFontSize
}
const TitleStyles = styled(HeadingLabel)<{ showCloseButton?: boolean }>`
  padding-top: var(--title-padding-top);
  color: var(--on-light);
`

const Title = ({ children, fontSize, tabletFontSize, mobileFontSize }: PropsWithChildren<TextProps>) => {
  return (
    <TitleStyles fontSize={fontSize ?? '3'} tabletFontSize={tabletFontSize} mobileFontSize={mobileFontSize ?? '2'}>
      {children}
    </TitleStyles>
  )
}

const SubtitleStyles = styled(SubheadingLabel)`
  color: var(--on-light);
`

const Subtitle = ({ children, fontSize, tabletFontSize, mobileFontSize }: PropsWithChildren<TextProps>) => {
  return (
    <SubtitleStyles fontSize={fontSize ?? '2'} tabletFontSize={tabletFontSize} mobileFontSize={mobileFontSize ?? '1'}>
      {children}
    </SubtitleStyles>
  )
}

const ButtonStyles = styled(GrandstandButton)`
  width: 100%;
  border-radius: 4px;
  text-transform: uppercase;
`

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  buttonSize?: ButtonSize
  buttonStyle?: ButtonStyle
}

const Button = ({ buttonSize: _, buttonStyle, children, ...props }: ButtonProps) => {
  return (
    <ButtonStyles buttonSize="sm" buttonStyle={buttonStyle ?? 'alt'} {...props}>
      {children}
    </ButtonStyles>
  )
}

const CloseButton = styled(GrandstandButton)`
  position: absolute;
  top: 20px;
  right: 16px;
  border: none;
  background-color: transparent;
`

export type PopupModalProps = {
  show: boolean
  onClose?: () => void
  showCloseButton?: boolean
  textAlign?: 'center' | 'left' | 'right'
}
const PopupModal = ({
  children,
  show = false,
  onClose = () => {},
  showCloseButton = false,
  textAlign = 'center',
}: PropsWithChildren<PopupModalProps>) => {
  useEffect(() => {
    if (!onClose || !show) {
      return
    }
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose()
      }
    }
    window.addEventListener('keyup', handleKeyDown)
    return () => {
      window.removeEventListener('keyup', handleKeyDown)
    }
  }, [onClose, show])
  return (
    <Wrapper show={show} showCloseButton={showCloseButton}>
      <Overlay onClick={onClose} />
      <Modal data-theme="light" textAlign={textAlign ?? 'center'}>
        {showCloseButton && (
          <CloseButton buttonStyle="on-image" buttonSize="min" onClick={onClose}>
            <GrandstandIcon icon="close" width="20px" />
          </CloseButton>
        )}
        {children}
      </Modal>
    </Wrapper>
  )
}
PopupModal.Modal = Modal
PopupModal.Button = Button
PopupModal.Title = Title
PopupModal.Subtitle = Subtitle

export default PopupModal
